<template>
    <div class="userManagement-container">
        <vab-query-form>
            <vab-query-form-left-panel :span="12">
                
                <!--<el-button icon="el-icon-delete" type="danger" @click="handleDelete">
                    批量禁用
                </el-button>-->
            
                <el-form :inline="true" :model="queryForm" @submit.native.prevent>
                    <el-form-item>
                        <el-input v-model.trim="queryForm.name"
                                  placeholder="请输入用户名"
                                  clearable />
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-search" type="primary" @click="queryData">
                            查询
                        </el-button>

                        <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
                            添加
                        </el-button>
                    </el-form-item>
                </el-form>
            </vab-query-form-left-panel>
        </vab-query-form>

        <el-table v-loading="listLoading"
                  :data="list"
                  border
                  :element-loading-text="elementLoadingText"
                  @selection-change="setSelectRows">
            <!--<el-table-column show-overflow-tooltip type="selection"></el-table-column>-->

            <el-table-column show-overflow-tooltip label="操作" width="200" align="center">
                <template #default="{ row }">
                    <el-button type="primary" @click="handleEdit(row)">编辑</el-button>

                    <el-button v-if="row.isDisable" type="warning" @click="handleDelete(row)">启用</el-button>
                    <el-button v-else type="danger" @click="handleDelete(row)">禁用</el-button>

                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="60px"
                             prop="id"
                             label="id"></el-table-column>
            <el-table-column show-overflow-tooltip
                             width="120px"
                             prop="name"
                             label="用户名"></el-table-column>
            <el-table-column show-overflow-tooltip
                             width="120px"
                             prop="mobile"
                             label="手机"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="100px"
                             label="状态">
                <template #default="{ row }">
                    <el-tag v-if="row.isDisable" type="info">禁用</el-tag>
                    <el-tag v-else type="success">启用</el-tag>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="160px"
                             prop="creationTime"
                             label="创建时间">
                <template slot-scope="scope">
                    {{scope.row.creationTime| DateFormat}}
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip label="权限">
                <template #default="{ row }">
                    <el-tag v-for="(item, index) in row.groups" :key="index">
                        {{ item.code }}
                    </el-tag>
                </template>
            </el-table-column>
        </el-table>



        <el-pagination background
                       :current-page="queryForm.pageIndex"
                       :page-size="queryForm.pageSize"
                       :layout="layout"
                       :total="total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>
        <edit ref="edit" @fetch-data="fetchData"></edit>
    </div>
</template>

<script>
    import { getUserList, doDelete } from '@/api/user';
    import Edit from './components/UserEdit';

    export default {
        name: 'User',
        components: { Edit },
        data() {
            return {
                list: null,
                listLoading: false,
                layout: 'total, sizes, prev, pager, next, jumper',
                total: 0,
                selectRows: '',
                elementLoadingText: '正在加载...',
                queryForm: {
                    pageIndex: 1,
                    pageSize: 10,
                    name: '',
                },
            }
        },
        created() {
            this.fetchData()
        },
        methods: {
            setSelectRows(val) {
                this.selectRows = val
            },
            handleEdit(row) {
                if (row.id) {
                    let userGroups = [];
                    if (row.groups) {
                        row.groups.forEach(function (item) {
                            userGroups.push(item.userGroupId);
                        });
                        row['userGroups'] = userGroups;
                    }
                    this.$refs['edit'].showEdit(row)
                } else {
                    this.$refs['edit'].showEdit()
                }
            },
            handleDelete(row) {
                if (row.id) {
                    let tips = row.isDisable ? '启用' : '禁用';
                    this.$baseConfirm(`你确定要${tips}当前项吗`, '提示', async () => {
                        const { isSucceed, message } = await doDelete({ id: row.id, isDisable: !row.isDisable });
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        this.fetchData()
                    })
                }
            },
            handleSizeChange(val) {
                this.queryForm.pageSize = val;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.queryForm.pageIndex = val;
                this.fetchData();
            },
            queryData() {
                this.queryForm.pageIndex = 1;
                this.fetchData();
            },
            async fetchData() {
                const { result, total } = await getUserList(this.queryForm);
                this.list = result;
                this.total = total;
            },
        },
    }
</script>
