<template>
    <el-dialog :title="title"
               :visible.sync="dialogFormVisible"
               width="500px"
               @close="close">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="用户名" prop="name">
                <el-input v-model.trim="form.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="passWord">
                <el-input v-model.trim="form.passWord"
                          type="password"
                          autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机" prop="mobile">
                <el-input v-model.trim="form.mobile" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="状态" prop="isDisable">
                <el-checkbox v-model="form.isDisable">
                    禁用
                </el-checkbox>
            </el-form-item>

            <el-form-item label="分组" prop="userGroups">
                <el-checkbox-group v-model="form.userGroups">
                    <el-checkbox v-for="(item,index) in groups" :label="item.id" :key="index">
                        {{item.name}}-{{item.code}}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { doEdit, getUserGrop, doAdd } from '@/api/user';

    export default {
        name: 'UserEdit',
        data() {
            return {
                form: {
                    id: 0,
                    name: '',
                    passWord: '',
                    mobile: '',
                    isDisable: false,
                    userGroups: [],
                },
                rules: {
                    name: [{ required: true, trigger: 'blur', message: '请输入用户名' }],
                    passWord: [{ required: true, trigger: 'blur', message: '请输入密码' }],
                    mobile: [{ required: true, trigger: 'blur', message: '请输入手机号' }],
                    userGroups: [{ required: true, trigger: 'blur', message: '请选择分组' }],
                },
                title: '',
                dialogFormVisible: false,
                queryForm: {
                    pageIndex: 1,
                    pagesize: 100,
                    name: '',
                },
                groups: null,
            }
        },
        created() {
            this.userGroup();
        },
        methods: {
            showEdit(row) {
                if (!row) {
                    this.title = '添加'
                } else {
                    this.title = '编辑';
                    this.form = Object.assign({}, row);
                   // console.log('[this.form]' + JSON.stringify(this.form));
                }
                this.dialogFormVisible = true
            },
            close() {
                this.$refs['form'].resetFields()
                this.form = this.$options.data().form
                this.dialogFormVisible = false
            },
            save() {
                this.$refs['form'].validate(async (valid) => {
                    if (!valid) return false;
                    if (this.form.id === 0) {
                        const { isSucceed, message } = await doAdd(this.form)
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        if (isSucceed) this.close();
                    } else {
                        const { isSucceed, message } = await doEdit(this.form)
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        if (isSucceed) this.close();
                    }
                    this.$emit('fetch-data');
                })
            },
            async userGroup() {
                const { isSucceed, message, result } = await getUserGrop(this.queryForm);
                if (!isSucceed) return this.$baseMessage(message, 'error');
                //console.log('[getUserGroup]' + JSON.stringify(result));
                this.groups = result;
            }
        },
    }
</script>
